import { useEffect, useRef, useState } from 'react';

import { Copy, H2, H3 } from '../../components/atoms/Typography';
import { Features as FeaturesElement } from '../../components/organisms/Features';
import { FeaturesGrid } from '../../components/organisms/FeaturesGrid';
import styled from 'styled-components';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { Faq, Faqs } from '../../components/atoms/Faq';
import { BackgroundColor } from '../../components/atoms/BackgroundColor';
import { Colors } from '../../styles/colors';
import {
  ResumePreview,
  ResumesPreviewCentralWrapper,
  ResumesPreviewLeftWrapper,
  ResumesPreviewRightWrapper,
  ResumesPreviewWrapper,
} from '../../components/molecules/ResumePreview';
import { FunctionalIFrameComponent } from '../../components/atoms/FunctionalIFrameComponent';

import { stateMozard } from '../../states/stateMozard';
import { stateBeethoven } from '../../states/stateBeethoven';
import { stateChopin } from '../../states/stateChopin';
import { coverLetterStateMozard } from '../../states/coverletterMozard';
import { coverLetterBeethoven } from '../../states/coverletterBeethoven';
import { coverLetterChopin } from '../../states/coverletterChopin';
import { CoverLetterPreview } from '../../components/molecules/CoverLetterPreview';
import { PrimaryButtonLink } from '../../components/atoms/Button/Buttons';
import { ChevronRightIcon } from '../../components/atoms/Icons';
import { media } from '../../styles/media';

const Features = () => {
  const initialNumberOfParagraphs = coverLetterStateMozard.coverLetter.content.length;
  const initialJobTitle = 'Composer and Musician';
  const [counter, setCounter] = useState(0);
  const interval = useRef<ReturnType<typeof setInterval>>();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let i = 1;
    interval.current = setInterval(() => {
      i = i + 1;
      setCounter(i);
    }, 500);
    return () => {
      clearInterval(interval.current);
      interval.current = undefined;
    };
  }, []);

  const stateMozardUpdated = { ...stateMozard };
  stateMozardUpdated.resume.basics.jobTitle = initialJobTitle.substring(0, (counter % initialJobTitle.length) + 1);
  const coverLetterStateMozardUpdated = JSON.parse(JSON.stringify(coverLetterStateMozard));
  coverLetterStateMozardUpdated.coverLetter.content = coverLetterStateMozard.coverLetter.content.slice(
    0,
    counter % initialNumberOfParagraphs
  );

  return (
    <>
      <Spacer y={32} />
      <TitleWrapper>
        <H3>We're always unveiling exciting new features to supercharge your Job Search!</H3>
      </TitleWrapper>
      <Spacer y={64} />
      <FeaturesGrid />
      <Spacer y={64} />
      <FeaturesElement />

      <Spacer y={64} />

      <H2>Frequently asked questions</H2>
      <Faqs>
        <Faq title="How can I start with JobsBoard.io?">
          Starting your job search journey with jobsboard.io is both simple and completely free! Just sign up for our
          Free plan, and you can immediately enhance your job hunting experience. Enjoy the freedom to use jobsboard.io
          for as long as you need; there's no trial period or time limit to worry about.
        </Faq>
        <Faq title="Is there any limit on the number of Job Applications?">
          While the Free plan offers 3 Jobs Boards and 20 Job Applications, our Premium plan opens the door to unlimited
          Job Applications, providing unparalleled flexibility and control.
        </Faq>
        <Faq title="Is there a time limit to use JobsBoard.io?">
          There is no time limit for using JobsBoard.io. You can use the platform indefinitely with basic customization
          options without any charge. The platform offers advanced customization features which come with a cost,
          clearly labeled within the app interface. You have the freedom to organize your job search effectively and
          choose to pay only for the additional, enhanced functionalities you need.
        </Faq>
        <Faq title="Is my data safe and secure?">
          Rest assured, your data is safeguarded with state-of-the-art encryption measures to uphold its
          confidentiality. Your credit card information is never stored for an extra layer of security. Routine backups
          are diligently performed to prevent data loss, providing an added layer of protection and facilitating swift
          recovery processes for your peace of mind.
        </Faq>
        <Faq title="Is the AI Assistant feature available in the Free plan?">
          No, the AI Assistant feature is exclusively offered in the Premium Plan. It aids in accelerating copywriting
          by offering suggestions and provides valuable assistance in your job search.
        </Faq>
        <Faq title="Is there any limit on the number of Job Applications I can manage?">
          Yes, there are some limitations with the Free plan. However, with the Premium plan, there are no limits! You
          have the freedom to manage as many Boards and Job Applications as you need. Just remember, for advanced
          customization options, upgrading to a paid subscription plan is necessary. Enjoy the flexibility of managing
          your Jobs board and applications without any restrictions!
        </Faq>
      </Faqs>
      <Spacer y={64} />

      <BackgroundColor backgroundColor={Colors.ContrastLightest} hasWave={false}>
        <TableWrapper>
          <Wrapper>
            <ResumesPreviewWrapper>
              <ResumesPreviewLeftWrapper>
                <FunctionalIFrameComponent title="resume">
                  <ResumePreview state={stateBeethoven} hasNavigation={false} />
                </FunctionalIFrameComponent>
              </ResumesPreviewLeftWrapper>
              <ResumesPreviewCentralWrapper>
                <FunctionalIFrameComponent title="resume">
                  <ResumePreview state={stateMozardUpdated} hasNavigation={false} />
                </FunctionalIFrameComponent>
              </ResumesPreviewCentralWrapper>
              <ResumesPreviewRightWrapper>
                <FunctionalIFrameComponent title="resume">
                  <ResumePreview state={stateChopin} hasNavigation={false} />
                </FunctionalIFrameComponent>
              </ResumesPreviewRightWrapper>
            </ResumesPreviewWrapper>
          </Wrapper>
          <Wrapper>
            <H2>AI Resume Builder</H2>
            <H3>Craft Your Ultimate Resume with our AI assistant.</H3>
            <Copy>
              Elevate your chances with a standout resume, customized swiftly for each application, opening doors to a
              plethora of career opportunities.
            </Copy>
            <Spacer y={32} />
            <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
              Sign Up for Free
            </PrimaryButtonLink>
          </Wrapper>
        </TableWrapper>
      </BackgroundColor>

      <Spacer y={64} />

      <BackgroundColor backgroundColor={Colors.White} hasWave={false}>
        <TableWrapper>
          <Wrapper>
            <H2>AI-Powered Cover Letter</H2>
            <H3>Craft Your Perfect Cover Letter with Ease</H3>
            <Copy>
              Impress employers with a tailored cover letter for every application, igniting your journey towards
              endless career opportunities.
            </Copy>
            <Spacer y={32} />
            <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
              Create your Cover Letter
            </PrimaryButtonLink>
          </Wrapper>
          <Wrapper>
            <ResumesPreviewWrapper>
              <ResumesPreviewLeftWrapper>
                <FunctionalIFrameComponent title="coverletter">
                  <CoverLetterPreview state={coverLetterBeethoven} />
                </FunctionalIFrameComponent>
              </ResumesPreviewLeftWrapper>
              <ResumesPreviewCentralWrapper>
                <FunctionalIFrameComponent title="coverletter">
                  <CoverLetterPreview state={coverLetterStateMozardUpdated} />
                </FunctionalIFrameComponent>
              </ResumesPreviewCentralWrapper>
              <ResumesPreviewRightWrapper>
                <FunctionalIFrameComponent title="coverletter">
                  <CoverLetterPreview state={coverLetterChopin} />
                </FunctionalIFrameComponent>
              </ResumesPreviewRightWrapper>
            </ResumesPreviewWrapper>
          </Wrapper>
        </TableWrapper>
      </BackgroundColor>
    </>
  );
};

export default Features;

const TitleWrapper = styled.div`
  margin-inline: auto;
  max-width: 800px;
  font-weight: 100;
  text-align: center;
`;

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${media.from_tablet`
    grid-template-columns: 1fr 1fr;
    gap: 64px;
  `}
`;

const Wrapper = styled.div``;
